import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { ProjectId } from '../../../models/Types';
import { Button, Form, Typography, Switch, Spin, Row, Col } from 'antd';
import ContactPicker from '../../contacts/components/ContactPicker';
import {
  SettingKey,
  Setting,
  CreateSetting,
  UpdateSetting,
} from '../../../models/Settings';
import {
  apiFetchDefaultSettings,
  apiSaveProjectSetting,
  apiUpdateProjectSetting,
} from '../../settings/api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    marginTop: theme.spacing.large,
    padding: theme.spacing.large,
    backgroundColor: theme.colors.application.background.default,
  },
  formItem: {
    marginBottom: theme.spacing.regular,
  },
  button: {
    marginTop: theme.spacing.large,
    float: 'right',
  },
}));
interface ProjectMailAssignmentSettingProps {
  className?: string;
  projectId: ProjectId;
}

export const ProjectMailAssignmentSetting: React.FC<
  ProjectMailAssignmentSettingProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, projectId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [isEnabled, setIsEnabled] = useState(false);
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [settingId, setSettingId] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchDefaultSettings = async () => {
      setIsFetching(true);
      const setting: SettingKey = {
        keys: ['SharedMailbox_DefaultAssignee'],
        scopeId: projectId,
        eSettingScope: 'project',
      };
      const { data } = await apiFetchDefaultSettings([setting]);
      if (data) {
        const settings: Setting = data[0];
        const defaultAssignee = JSON.parse(settings.jsonValue);
        form.setFieldsValue({
          isEnabled: defaultAssignee.UserId ? true : false,
        });
        setIsEnabled(defaultAssignee.UserId ? true : false);
        form.setFieldsValue({ contactId: defaultAssignee.UserId });
        setSettingId(settings.settingId);
      }
      setIsFetching(false);
    };
    fetchDefaultSettings();
  }, [projectId, form]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const settingData: CreateSetting = {
        jsonValue: JSON.stringify({
          UserId: isEnabled ? values.contactId : '',
        }),
        parentSettingId: '',
        relationId: '',
        isDefault: true,
        description: '',
      };
      if (settingId !== '00000000-0000-0000-0000-000000000000') {
        await apiUpdateProjectSetting(settingId, settingData as UpdateSetting);
      } else {
        await apiSaveProjectSetting(
          projectId,
          'SharedMailbox_DefaultAssignee',
          settingData as CreateSetting
        );
      }
    } catch (error) {}
    setIsFormChanged(false);
    updateSaveButtonState(true);
  };
  const handleSwitchChange = (checked: boolean) => {
    setIsEnabled(checked);
    setIsFormChanged(true);
    if (!checked) {
      form.setFieldsValue({ contactId: null });
    }
    updateSaveButtonState();
  };

  const handleFormChange = () => {
    setIsFormChanged(true);
    updateSaveButtonState();
  };

  const updateSaveButtonState = (isEnabledOverride?: boolean) => {
    const isDisabled =
      !isFormChanged && !form.getFieldValue('contactId') && !isEnabled;
    setIsSaveDisabled(isEnabledOverride ?? isDisabled);
  };
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Typography.Title>
        {t(`projects:projectMailAssignmentSetting.headline`)}
      </Typography.Title>
      <p>{t(`projects:projectMailAssignmentSetting.description`)}</p>

      <Spin spinning={isFetching}>
        <Form layout="vertical" form={form} onValuesChange={handleFormChange}>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item
                name={'isEnabled'}
                label={t('projects:projectMailAssignmentSetting.enable')}
                className={classes.formItem}
              >
                <Switch checked={isEnabled} onChange={handleSwitchChange} />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                hidden={!isEnabled}
                name="contactId"
                label={t('timeRecords:form.labels.contactId')}
                rules={[
                  {
                    required: isEnabled ? true : false,
                  },
                ]}
              >
                <ContactPicker
                  disabled={!isEnabled}
                  onlyInternalProject
                  projectId={projectId}
                />
              </Form.Item>
            </Col>
          </Row>

          <Button
            type="primary"
            onClick={handleSave}
            className={classes.button}
            disabled={isSaveDisabled}
          >
            {t('common:save')}
          </Button>
        </Form>
      </Spin>
    </div>
  );
};

export default ProjectMailAssignmentSetting;
