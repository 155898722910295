import { ApiResult, apiUrl } from '../../../api';
import { HourlyRateSuggestion } from '../../../models/HourlyRate';
import {
  CreateProjectFileSystemStructure,
  CreateSetting,
  ProjectFileSystemStructure,
  Setting,
  SettingKey,
  UpdateSetting,
} from '../../../models/Settings';
import {
  EMailSuffix,
  GlobalProjectSettingId,
  GlobalProjectSettingType,
  HourlyRateSuggestionId,
} from '../../../models/Types';
import { getAccessToken } from '../../../store/authEffect';
import jsonMergePatch from 'json-merge-patch';
import { KilometerRateSuggestion } from '../../../models/KilometerRate';
import {
  Configuration,
  GlobalProjectSetting,
} from '../../../models/Configuration';
import { DocumentTemplateFindTag } from '../../../models/DocumentTemplate';
import fetchWithRetry from '../../../util/fetchWithRetry';
import {
  ContactImportProcess,
  CsvMappingDto,
  UpdateContactCompanyFromImportProcessDto,
} from '../../../models/ContactImportProcess';
import {
  CreateInternalJobTitleSuggestion,
  InternalJobTitleSuggestion,
} from '../../../models/InternalJobTitleSuggestion';
import {
  CreateExternalJobTitleSuggestion,
  ExternalJobTitleSuggestion,
} from '../../../models/ExternalJobTitleSuggestion';
import {
  CreateProjectJobTitleSuggestionDto,
  ProjectJobTitleSuggestionDto,
} from '../../../models/ProjectJobTitle';
import { ContactClassificationDto } from '../../../models/ContactClassifications';
import { CompanyClassificationDto } from '../../../models/CompanyClassifications';

export const apiFetchEmailSuffixes: () => Promise<
  ApiResult<EMailSuffix[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/setting/EmailSuffix`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectFileStructure: (
  signal?: AbortSignal
) => Promise<ApiResult<ProjectFileSystemStructure[]>> = async (signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ProjectFileSystemStructure`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDocumentTemplatesForTags: (
  tags: string[],
  signal?: AbortSignal
) => Promise<ApiResult<DocumentTemplateFindTag[]>> = async (tags, signal) => {
  const queryString = encodeURIComponent(tags.join(','));
  const result = await fetchWithRetry(
    `${apiUrl}/document/DocumentTemplate/tag?tags=${queryString}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchHourlyRateSuggestions: (
  signal?: AbortSignal
) => Promise<ApiResult<HourlyRateSuggestion[]>> = async (signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/HourlyRateSuggestion`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateHourlyRateSuggestion: (
  suggestion: HourlyRateSuggestion
) => Promise<ApiResult<HourlyRateSuggestion>> = async (suggestion) => {
  const { hourlyRateSuggestionId, ...jsonData } = suggestion;

  const result = await fetchWithRetry(
    `${apiUrl}/setting/HourlyRateSuggestion`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(jsonData),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateHourlyRateSuggestion: (
  updatedHourlyRateSuggestion: HourlyRateSuggestion,
  originalRateSuggestion: HourlyRateSuggestion
) => Promise<ApiResult<HourlyRateSuggestion>> = async (
  updatedHourlyRateSuggestion,
  originalRateSuggestion
) => {
  const patch = jsonMergePatch.generate(
    originalRateSuggestion,
    updatedHourlyRateSuggestion
  );
  const result = await fetchWithRetry(
    `${apiUrl}/setting/HourlyRateSuggestion/${updatedHourlyRateSuggestion.hourlyRateSuggestionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/merge-patch+json',
      },
      method: 'PATCH',
      body: JSON.stringify({
        ...patch,
        rowVersion: originalRateSuggestion.rowVersion,
      }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiDeleteHourlyRateSuggestion: (
  hourlyRateSuggestionId: HourlyRateSuggestionId
) => Promise<ApiResult<void>> = async (hourlyRateSuggestionId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/HourlyRateSuggestion/${hourlyRateSuggestionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'DELETE',
    }
  );
  return {
    result,
  };
};

export const apiFetchKilometerRateSuggestion: (
  signal?: AbortSignal
) => Promise<ApiResult<KilometerRateSuggestion>> = async (signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/KilometerRateSuggestion`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiSetKilometerRateSuggestion: (
  suggestion: KilometerRateSuggestion
) => Promise<ApiResult<KilometerRateSuggestion>> = async (suggestion) => {
  const { kilometerRateSuggestionId, ...jsonData } = suggestion;

  const result = await fetchWithRetry(
    `${apiUrl}/setting/KilometerRateSuggestion`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(jsonData),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchConfigurations: (
  signal?: AbortSignal
) => Promise<ApiResult<Configuration[]>> = async (signal) => {
  const result = await fetchWithRetry(`${apiUrl}/setting/Configuration`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
    signal,
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchGlobalProjectSetting: (
  type: GlobalProjectSettingType,
  signal?: AbortSignal
) => Promise<ApiResult<GlobalProjectSetting[]>> = async (type, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/globalProjectSetting/${type}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateConfiguration: (
  config: Configuration
) => Promise<ApiResult<Configuration>> = async (config) => {
  const result = await fetchWithRetry(`${apiUrl}/setting/Configuration`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(config),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateGlobalProjectSetting: (
  type: GlobalProjectSettingType,
  setting: GlobalProjectSetting
) => Promise<ApiResult<GlobalProjectSetting>> = async (type, setting) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/globalProjectSetting/${type}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiSetDefaultGlobalProjectSetting: (
  settingId: GlobalProjectSettingId
) => Promise<ApiResult<GlobalProjectSetting>> = async (settingId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/globalProjectSetting/${settingId}/setDefault`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'PUT',
    }
  );
  return {
    result,
  };
};

export const apiArchiveGlobalProjectSetting: (
  type: GlobalProjectSettingType,
  settingId: GlobalProjectSettingId
) => Promise<ApiResult<boolean>> = async (type, settingId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/globalProjectSetting/${settingId}/${type}/archive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  const data = null;
  return {
    result,
    data,
  };
};

export const apiUpdateConfiguration: (
  updatedConfiguration: Configuration,
  originalConfiguration: Configuration
) => Promise<ApiResult<Configuration>> = async (
  updatedConfiguration,
  originalConfiguration
) => {
  const patch = jsonMergePatch.generate(
    originalConfiguration,
    updatedConfiguration
  );
  const result = await fetchWithRetry(
    `${apiUrl}/setting/Configuration/${updatedConfiguration.configurationId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/merge-patch+json',
      },
      method: 'PATCH',
      body: JSON.stringify({
        ...patch,
        rowVersion: originalConfiguration.rowVersion,
      }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateGlobalProjectSetting: (
  type: GlobalProjectSettingType,
  setting: GlobalProjectSetting
) => Promise<ApiResult<GlobalProjectSetting>> = async (type, setting) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/globalProjectSetting/${type}/${setting.globalProjectSettingId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateProjectFileSystemStructure: (
  projectFileSystemStructure: CreateProjectFileSystemStructure
) => Promise<ApiResult<ProjectFileSystemStructure>> = async (
  projectFileSystemStructure
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ProjectFileSystemStructure`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(projectFileSystemStructure),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchConfigurationByKey: (
  keyName: string
) => Promise<ApiResult<Configuration>> = async (keyName) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/configuration/key/${keyName}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchMyImportProcesses: () => Promise<
  ApiResult<ContactImportProcess[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/contact/importer/me`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchContactImportProcess: (
  contactImportProcessId: string
) => Promise<ApiResult<ContactImportProcess>> = async (
  contactImportProcessId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/importer/process/${contactImportProcessId}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiUpdateContactsFromImportProcess: (
  contactImportProcessId: string,
  updateContactCompanyDto: UpdateContactCompanyFromImportProcessDto
) => Promise<ApiResult<void>> = async (
  contactImportProcessId,
  updateContactCompanyDto
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/importer/process/${contactImportProcessId}/updateContacts`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateContactCompanyDto),
    }
  );

  return {
    result,
    data:
      result.status >= 200 && result.status < 300 ? null : await result.json(),
  };
};

export const apiDeleteContactImportProcess: (
  contactImportProcessId: string
) => Promise<ApiResult<void>> = async (contactImportProcessId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/importer/${contactImportProcessId}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );

  return {
    result,
  };
};

export const apiUploadCsvWithMapping: (
  file: File,
  mappingData: CsvMappingDto
) => Promise<ApiResult<void>> = async (file, mappingData) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('data', JSON.stringify(mappingData));

  const result = await fetch(`${apiUrl}/contact/importer/csv`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: formData,
  });

  return {
    result,
  };
};

//api/contact/importer/{ID}/validate POST
export const apiValidateContactImportProcess: (
  contactImportProcessId: string
) => Promise<ApiResult<void>> = async (contactImportProcessId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/importer/${contactImportProcessId}/validate`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );

  return {
    result,
  };
};

export const apiStartContactImportProcess: (
  contactImportProcessId: string
) => Promise<ApiResult<void>> = async (contactImportProcessId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/importer/${contactImportProcessId}/start`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );

  return {
    result,
  };
};

export const apiFetchProjectVisibilityDefaultSetting: (
  settingKey: SettingKey[]
) => Promise<ApiResult<Setting>> = async (settingKey) => {
  const result = await fetchWithRetry(
    `${apiUrl}/settings/Settings/GetSettings`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(settingKey),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDefaultSettings: (
  settingKeys: SettingKey[]
) => Promise<ApiResult<Setting>> = async (settingKeys) => {
  const result = await fetchWithRetry(
    `${apiUrl}/settings/Settings/GetSettings`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(settingKeys),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiSaveProjectSetting: (
  projectId: string,
  key: string,
  setting: CreateSetting
) => Promise<ApiResult<Setting>> = async (projectId, key, setting) => {
  const result = await fetchWithRetry(
    `${apiUrl}/settings/Settings/project/${projectId}/${key}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectSetting: (
  settingId: string,
  setting: UpdateSetting
) => Promise<ApiResult<Setting>> = async (settingId, setting) => {
  const result = await fetchWithRetry(
    `${apiUrl}/settings/Settings/${settingId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchInternalJobTitleSuggestions: () => Promise<
  ApiResult<ProjectJobTitleSuggestionDto[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ProjectJobTitle?internalFunctions=true&externalFunctions=false`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateInternalJobTitleSuggestion: (
  createInternalJobTitleSuggestion: CreateInternalJobTitleSuggestion
) => Promise<ApiResult<InternalJobTitleSuggestion>> = async (
  createInternalJobTitleSuggestion
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/InternalJobTitleSuggestion`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createInternalJobTitleSuggestion),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateInternalJobTitleSuggestion: (
  updatedInternalJobTitle: InternalJobTitleSuggestion,
  originalInternalJobTitle: InternalJobTitleSuggestion
) => Promise<ApiResult<InternalJobTitleSuggestion>> = async (
  updatedInternalJobTitle,
  originalInternalJobTitle
) => {
  const patch = jsonMergePatch.generate(
    originalInternalJobTitle,
    updatedInternalJobTitle
  );
  const result = await fetchWithRetry(
    `${apiUrl}/setting/InternalJobTitleSuggestion/${updatedInternalJobTitle.internalJobTitleSuggestionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/merge-patch+json',
      },
      method: 'PATCH',
      body: JSON.stringify({
        ...patch,
        rowVersion: originalInternalJobTitle.rowVersion,
      }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteInternalJobTitleSuggestion: (
  internalJobTitleSuggestionId: string
) => Promise<ApiResult<void>> = async (internalJobTitleSuggestionId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/InternalJobTitleSuggestion/${internalJobTitleSuggestionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'DELETE',
    }
  );
  return {
    result,
  };
};

export const apiFetchExternalJobTitleSuggestions: () => Promise<
  ApiResult<ProjectJobTitleSuggestionDto[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ProjectJobTitle?internalFunctions=false&externalFunctions=true`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateExternalJobTitleSuggestion: (
  createExternalJobTitleSuggestion: CreateExternalJobTitleSuggestion
) => Promise<ApiResult<ExternalJobTitleSuggestion>> = async (
  createExternalJobTitleSuggestion
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ExternalJobTitleSuggestion`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createExternalJobTitleSuggestion),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateExternalJobTitleSuggestion: (
  updatedExternalJobTitle: ExternalJobTitleSuggestion,
  originalExternalJobTitle: ExternalJobTitleSuggestion
) => Promise<ApiResult<ExternalJobTitleSuggestion>> = async (
  updatedExternalJobTitle,
  originalExternalJobTitle
) => {
  const patch = jsonMergePatch.generate(
    originalExternalJobTitle,
    updatedExternalJobTitle
  );
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ExternalJobTitleSuggestion/${updatedExternalJobTitle.externalJobTitleSuggestionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/merge-patch+json',
      },
      method: 'PATCH',
      body: JSON.stringify({
        ...patch,
        rowVersion: originalExternalJobTitle.rowVersion,
      }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteExternalJobTitleSuggestion: (
  externalJobTitleSuggestionId: string
) => Promise<ApiResult<void>> = async (externalJobTitleSuggestionId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ExternalJobTitleSuggestion/${externalJobTitleSuggestionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'DELETE',
    }
  );
  return {
    result,
  };
};

export const apiFetchProjectJobTitleSuggestions: () => Promise<
  ApiResult<ProjectJobTitleSuggestionDto[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/setting/ProjectJobTitle`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    method: 'GET',
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateProjectJobTitleSuggestion: (
  newJobTitles: CreateProjectJobTitleSuggestionDto[]
) => Promise<ApiResult<void>> = async (newJobTitles) => {
  const result = await fetchWithRetry(`${apiUrl}/setting/ProjectJobTitle`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(newJobTitles),
  });
  return {
    result,
  };
};

export const apiUpdateProjectJobTitleSuggestion: (
  updatedJobTitle: ProjectJobTitleSuggestionDto
) => Promise<ApiResult<void>> = async (updatedJobTitle) => {
  const result = await fetchWithRetry(`${apiUrl}/setting/ProjectJobTitle`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
      'If-Match': updatedJobTitle.rowVersion, // For concurrency handling
    },
    method: 'PUT',
    body: JSON.stringify(updatedJobTitle),
  });
  return {
    result,
  };
};

export const apiDeleteProjectJobTitleSuggestion: (
  name: string
) => Promise<ApiResult<void>> = async (name) => {
  const result = await fetchWithRetry(`${apiUrl}/setting/ProjectJobTitle`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    // body list of names to delete
    body: JSON.stringify([name]),
    method: 'DELETE',
  });
  return {
    result,
  };
};

export const apiUploadProjectJobTitleSuggestionsCsv: (
  file: File
) => Promise<ApiResult<void>> = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const result = await fetchWithRetry(`${apiUrl}/setting/ProjectJobTitle/csv`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    method: 'POST',
    body: formData,
  });
  return {
    result,
  };
};

export const apiFetchContactClassifications: () => Promise<
  ApiResult<ContactClassificationDto[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ContactClassification`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return { result, data };
};

/**
 * Update a specific Contact Classification.
 * @param classification The updated classification object.
 */
export const apiUpdateContactClassification: (
  classification: ContactClassificationDto
) => Promise<ApiResult<void>> = async (classification) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ContactClassification/${classification.contactClassificationId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      body: JSON.stringify(classification),
    }
  );

  return { result };
};

/**
 * Delete a Contact Classification by ID.
 * @param contactClassificationId The ID of the classification to delete.
 */
export const apiDeleteContactClassification: (
  names: string[]
) => Promise<ApiResult<void>> = async (names) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ContactClassification`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      body: JSON.stringify(names),
    }
  );

  return { result };
};

/**
 * Create a new Contact Classification.
 * @param classification The new classification object.
 */
export const apiCreateContactClassification: (
  classification: ContactClassificationDto
) => Promise<ApiResult<void>> = async (classification) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ContactClassification`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      body: JSON.stringify(classification),
    }
  );

  return { result };
};

export const apiFetchCompanyClassifications: () => Promise<
  ApiResult<CompanyClassificationDto[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/CompanyClassification`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return { result, data };
};

/**
 * Update a specific Company Classification.
 * @param classification The updated classification object.
 */
export const apiUpdateCompanyClassification: (
  classification: CompanyClassificationDto
) => Promise<ApiResult<void>> = async (classification) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/CompanyClassification/${classification.companyClassificationId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      body: JSON.stringify(classification),
    }
  );

  return { result };
};

/**
 * Delete a Company Classification by ID.
 * @param companyClassificationId The ID of the classification to delete.
 */
export const apiDeleteCompanyClassification: (
  names: string[]
) => Promise<ApiResult<void>> = async (names) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/CompanyClassification`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      body: JSON.stringify(names),
    }
  );

  return { result };
};

/**
 * Create a new Company Classification.
 * @param classification The new classification object.
 */
export const apiCreateCompanyClassification: (
  classification: CompanyClassificationDto
) => Promise<ApiResult<void>> = async (classification) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/CompanyClassification`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      body: JSON.stringify(classification),
    }
  );

  return { result };
};
